import React, { useState, useEffect } from "react"
import logo from "../../images/logo.png"

const Header = () => {
  const [animated, setAnimated] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimated(true)
    }, 500)
  }, [])

  return (
    <header className="relative w-full h-24 flex flex-col text-xl justify-center text-right">
      <section className="w-full h-full px-8 flex flex-row items-center justify-center">
        <div className="w-full max-w-4xl h-full flex flex-row items-center justify-between">
          {/** Logo */}
          <img alt="CryptoForza GT Logo" src={logo} className="w-56 max-w-xs" />
          <section className="flex flex-col justify-center items-end sm:flex-row gap-2 text-sm sm:text-xl sm:gap-8">
            <a
              className="flex flex-row justify-end font-a4speed hover:text-primary focus:text-primary outline-none"
              href="https://www.cryptoforzagt.org"
              target="_blank"
              rel="noreferrer"
            >
              About
            </a>
            <a
              className="flex flex-row justify-end font-a4speed hover:text-primary focus:text-primary outline-none"
              href="https://crypto-forza.gitbook.io/whitepaper/"
              target="_blank"
              rel="noreferrer"
            >
              White paper
            </a>
          </section>
        </div>
      </section>
      {/** Animated border-bottom */}
      <div
        className={`absolute inset-x-0 bottom-0 h-1 bg-white transform-gpu duration-1000 ease-out ${
          animated ? "translate-x-0" : "-translate-x-full"
        }`}
      ></div>
    </header>
  )
}

export default Header
